import React from 'react';
import PropTypes from 'prop-types';
import { setIsPlainSurvey } from 'js/reducers/bgClub.js';
import { connect } from 'react-redux';
import notification from 'cccisd-notification';

class SetSurveyType extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        setIsPlainSurvey: PropTypes.func,
        isPlainSurvey: PropTypes.bool,
    };

    static defaultProps = {
        isPlainSurvey: false,
    };

    componentDidMount() {
        const message = this.props.isPlainSurvey
            ? 'Switched to plain surveys'
            : 'Switched to normal surveys';

        this.props.setIsPlainSurvey(this.props.isPlainSurvey);
        notification(message);
        this.props.history.push(`/organizer${this.props.location.search}`);
    }

    render() {
        return null;
    }
}

export default connect(
    null,
    { setIsPlainSurvey }
)(SetSurveyType);
